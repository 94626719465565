html,
body {
    height: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

@font-face {
  font-family: 'test';
  src: url('/test.ttf');
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


/* 假设设计稿宽度 1200px， 实际开发以设计稿为准 */
/* 小屏自适应, 1rem = 8.333vw  */
html {
  /* (100vw / 1200) * 100 */
  font-size: 8.333vw;
  height: 100%;
}

body {
  font-size: 12px;
  height: 100%;
}
#__next {
  height: 100%; 
}

/* 大屏适配, 1rem = 100px */
@media (min-width: 1200px) {
  html {
    font-size: 100px;
  }
}

/* 更小屏幕适配, 1rem = 75px */
@media (max-width: 900px) {
  html {
    font-size: 75px;
  }
}
